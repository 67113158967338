<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <template v-if="clubUpdated == true">
        <v-alert
          dismissible
          type="success"
        >
          Club Details have been updated
        </v-alert>
      </template>
      <template v-if="clubUpdated == false">
        <v-alert
          dismissible
          type="danger"
        >
          Club Details failed to update
        </v-alert>
      </template>
      <v-row>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                <v-toolbar-title>Club Details</v-toolbar-title>
                <v-spacer />
                <v-btn
                  text
                  @click="showStripeField = true"
                >
                  Change Stripe ID
                </v-btn>
                <template v-if="showStripeField">
                  <v-text-field
                    v-model="stripeIdChange"
                    label="Enter new ID"
                  />
                  <v-btn
                    text
                    @click="changeStripeId"
                  >
                    Submit
                  </v-btn>
                </template>
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <template v-if="defaultClientDetailType === 'General'">
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Record ID
                        </h3>
                        <br>
                        <p>{{ selectedClub._id || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Club Name
                        </h3>
                        <br>
                        <p>{{ selectedClub.name || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Status
                        </h3>
                        <br>
                        <p>{{ selectedClub.status || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Assigned SMCs
                        </h3>
                        <br>
                        <p>{{ selectedClub.smcs || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Registrant',
    data () {
      return {
        showStripeField: false,
        stripeIdChange: '',
        page: {
          title: 'Your Roster',
          icon: 'user',
        },
        breadcrumbs: [
          {
            text: 'Users',
            disabled: false,
            to: '/user/users/',
          },
          {
            text: 'User Details',
            disabled: true,
          },
        ],
        auditHeaders: [
          {
            text: 'Reason',
            value: 'reason',
          },
          {
            text: 'Resource',
            value: 'route',
          },
          {
            text: 'Method',
            value: 'method',
          },
          {
            text: 'When',
            value: 'createdAt',
          },
        ],
        clientDetailTypes: [
          'General',
          'Location',
          'Medical',
        ],
        defaultClientDetailType: 'General',
      }
    },
    created () {
      this.$store.dispatch('viewClub', this.$route.params.id)
    },
    computed: {
      ...mapGetters(['selectedClub', 'clubUpdated']),
    },
    methods: {
      ...mapActions(['updateClub']),
      back () {
        this.$router.back()
      },
      changeClientDetailsScreen (screen) {
        this.defaultClientDetailType = screen
      },
      approve () {
        this.$store.dispatch('approveRegistrant', this.registrantQuery._id)
      },
      refreshAudit () {
        this.$store.dispatch('showUserAudit')
      },
      changeStripeId () {
        this.showStripeField = false
        const newId = this.stripeIdChange
        const clubId = this.$route.params.id
        this.updateClub({ id: clubId, stripeKey: newId })
      },
    },
  }
</script>
